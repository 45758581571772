<template>
  <div>
    <offer-home :offers="offers"/>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'

import OfferHome from '@/components/home/offer/Offer'
import useAPI from '@/utils/useAPI'

export default {
  components: {
    OfferHome,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { offers } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      offers,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () { },
  created () {
  }
}
</script>

<style lang="scss">

</style>
<template>
  <div>
    <search-filter
        :items="offers"
        :title="'Offers'"
        :keyTitleForAlphabeticalSort="'title'"
        :additionalSortOptions="additionalSortOptions"
        :selectedFilters="['_filter_isActive']"
        :selectedView="'list'"
        :excludedKeys="['offers']"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="true"
        @columnClick="$event.billNumber == null?selectOffer($event):$router.push({ name: 'Offer view', params: { offerId: $event.id }})"
        @addItem="addOffer"
    >
      <!--      result infos-->
      <template #resultInfos="{items}">
        ({{ items.length }})
        <span>{{ currency(getPreTaxAmounts(items)) }} HT</span>
      </template>

      <!--      Grid view-->
      <template v-slot:gridView="{item}">
        <card-offer
            :offer="item"
            @click="item.billNumber == null?selectOffer(item):$router.push({ name: 'Offer view', params: { offerId: item.id }})"
            @deleteOffer="deleteOfferAlert(item)"
        />
      </template>


      <!--      List view-->

      <!--      Cell bill number-->
      <template #listView_cell_billNumber="{item}">
        <span v-if="item.billNumber != null">{{ item.billNumber }}</span>
        <span v-else>-</span>
      </template>

      <!--      Cell billing date-->
      <template #listView_cell_billingDate="{item}">
        {{ item.billingDate|moment('L') }}
      </template>

      <!--      Column status-->
      <template #listView_head_status="{item}">
        <icon icon="chart-line"/>
      </template>
      <template #listView_cell_status="{item}">
        <b-avatar
            :id="`offer-row-${item.id}`"
            size="32"
            :variant="`light-${getOfferIconStatus(item).variant}`"
        >
          <icon
              :icon="getOfferIconStatus(item).icon"
          />
        </b-avatar>
        <b-tooltip
            :target="`offer-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ capitalize($t(getOfferStatus(item))) }}
          </p>
        </b-tooltip>
      </template>

      <!--      Cell contact-->
      <template #listView_cell_contact="{item}">
        {{ item.customerCompany ? item.customerCompany._display : item.customerIndividual._display }}
      </template>

      <!--      Cell pre tax amount-->
      <template #listView_cell_preTaxAmount="{item}">
        {{ currency(item.preTaxAmount) }}
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="$router.push({ name: 'Offer view', params: { offerId: item.id }})"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadOfferQuote(item)"
              v-b-tooltip.hover.left="capitalize($t('Download'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


          <b-dropdown
              variant="link"
              toggle-class="p-0"
              right
              no-caret
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Validate-->
            <b-dropdown-item
                v-if="item._isValidated == false && item.isDeclined != true"
                @click.stop="validateOfferLocal(item)"
            >
              <icon
                  icon="check"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('ValidatedOffer') }}</span>
            </b-dropdown-item>

            <!--          Decline-->
            <b-dropdown-item
                v-if="item._isValidated == false && item.isDeclined != true && item.isArchived == false"
                @click.stop="declinedOfferLocal(item, true)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('DeclinedOffer') }}</span>
            </b-dropdown-item>

            <!--          Resume-->
            <b-dropdown-item
                v-if="item.isDeclined == true"
                @click.stop="declinedOfferLocal(item, false)"
            >
              <icon
                  icon="check"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('Resume') }}</span>
            </b-dropdown-item>

            <!--          Edit-->
            <b-dropdown-item
                v-if="item._isValidated == false && item.isDeclined != true"
                @click.native.stop="$router.push({ name: 'Offer edit', params: { offerId: item.id }})"
            >
              <icon
                  icon="edit"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('Edit') }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                v-if="item.isArchived == false"
                @click.stop="archivateOfferLocal(item, true)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Activate-->
            <b-dropdown-item
                v-if="item.isArchived == true"
                @click.stop="archivateOfferLocal(item, false)"
            >
              <icon
                  icon="box-open"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Unarchive')) }}</span>
            </b-dropdown-item>

            <!--          Transform to outgoing invoice-->
            <b-dropdown-item
                v-if="
                item._isValidated == true &&
                item.isArchived == false &&
                item.isCanceled == false &&
                item.isDeclined == false
                "
                @click.native.stop="transformProductsToOutgoingInvoiceLocal(item)"
            >
              <icon
                  icon="file-invoice-dollar"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('transformOfferToOutgoingInvoice')) }}</span>
            </b-dropdown-item>

            <!--          Duplicate -->
            <b-dropdown-item
                v-if="item.isDeclined != true && item.isArchived == false && item.isCanceled == false"
                @click.native.stop="duplicateOffer(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                v-if="item._isValidated == false"
                @click.native.stop="deleteOfferAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>


          </b-dropdown>

        </div>
      </template>

    </search-filter>


    <!-- modal offer-->
    <modal-offer
        :offer.sync="currentOffer"
        :title="offerModalTitle"
        :isOpen.sync="offerModalShow"
        @submitValidatedOffer="submitValidatedOfferLocal"
    />

    <!-- modal offer validation-->
    <modal-offer-validation
        :offer.sync="currentOffer"
        :isOpen.sync="isOfferValidationModalOpen"
        @submitValidatedOfferValidationForm="submitValidatedOfferValidationForm"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinOffers } from '@/views/sales/offers/mixinOffers'
import { useOffers } from '@/views/sales/offers/useOffers'

import SearchFilter from '@/components/searchFilter/SearchFilter'
import CardOffer from '@/components/card/Offer'
import i18n from '@/libs/i18n'
import ButtonView from '@/components/button/View'
import ButtonDownload from '@/components/button/Download'
import ButtonDropdown from '@/components/button/Dropdown'

export default {
  components: {
    SearchFilter,
    CardOffer,
    ButtonView,
    ButtonDownload,
    ButtonDropdown
  },
  mixins: [mixinOffers],
  props: {
    offers: {
      type: Array,
      default: () => []
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'billNumber', label: '#', sortable: true },
      { key: 'billingDate', label: i18n.t('BillingDate'), sortable: true },
      { key: 'status', label: i18n.t('status'), sortable: false },
      { key: 'contact', label: i18n.t('customer'), sortable: true },
      { key: 'title', label: i18n.t('title'), sortable: false },
      { key: 'preTaxAmount', label: i18n.t('preTaxAmount'), sortable: true },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(props.offers, () => {
      if (props.offers.length) {
        props.offers[0]._filters.forEach(filter => {
          if (additionalSortOptions.value.some(el => el.key == filter.key) == false) {
            additionalSortOptions.value.push(filter)
          }
        })
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      getOfferStatus,
      getOfferIconStatus,
    } = useOffers()

    const enableFilters = () => {
      if (props.offers.length) {
        props.offers[0]._filters.forEach(filter => {
          if (additionalSortOptions.value.some(el => el.key == filter.key) == false) {
            additionalSortOptions.value.push(filter)
          }
        })
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    enableFilters()

    return {
      // Components

      // Data
      additionalSortOptions,
      tableColumns,

      // Computed

      // Methods
      getOfferStatus,
      getOfferIconStatus,
      enableFilters,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    offers: function () {
      this.enableFilters()
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>